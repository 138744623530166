<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <group-menu></group-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="drawer-bar auto">
          <span class="font16"><b>筛选器管理</b></span>
        </div>
        <div class="department-content auto">
          <div class="depart-box depart-l fl">
            <div class="left-top auto">
              <a-input-search v-model="searchValue" placeholder="请输入关键字" size="small" style="width: 200px; float: left;" allowClear />
              <a href="javascript:;" class="fr" style="margin-top: 1px;" @click="addFilter">添加</a>
            </div>
            <div class="left-con auto" :style="'minHeight:' + boxHeight + 'px'">
              <ul>
                <li v-for="(item, index) in listData" :key="index" @click="chooseList(item)" :class="current_row.id === item.id ? 'cur' : ''" v-show="item.name.indexOf(searchValue) > -1">
                  <span class="fl" :title="item.name">{{item.name}}</span>
                  <a-popconfirm
                    title="确定要删除吗?"
                    ok-text="确定"
                    cancel-text="取消"
                    placement="topRight"
                    @confirm="deleteFilter(item)"
                  >
                    <a-icon type="delete" class="fr" @click.stop />
                  </a-popconfirm>
                </li>
              </ul>
            </div>
          </div>
          <div class="depart-box depart-r fl" v-if="current_row.id || isAdd">
            <div class="depart-con auto">
              <div id="no-margin-form" class="right-form">
                <a-form-model
                    id="filter-form"
                    layout="inline"
                    ref="itemForm"
                    :model="itemForm"
                    class="ant-advanced-search-form"
                    :label-col="{span: 3}"
                    :wrapper-col="{span: 21}"
                  >
                  <a-form-model-item label="筛选器名称" prop="name">
                    <a-input v-model="itemForm.name" placeholder="请输入名称" size="small" style="width:100%;"/>
                  </a-form-model-item>
                  <a-form-model-item label="筛选器公式" prop="expressionName">
                    <a-input type="textarea" v-model="itemForm.expressionName" readOnly placeholder="" size="small" style="width:100%;"/>
                  </a-form-model-item>
                  <a-form-model-item label="" style="margin-top: 5px;" :wrapper-col="{span: 24}">
                    <div class="inside-box auto boxstyle">
                      <div class="box-left auto">
                        <div class="relationbox auto" v-if="itemForm.expressionContent.c.length > 1">
                          条件关系：
                          <a-radio-group button-style="solid" v-model="itemForm.expressionContent.l" size="small">
                            <a-radio-button :value="item.id" v-for="(item, index) in relationOptions" :key="index">
                              {{item.name}}
                            </a-radio-button>
                          </a-radio-group>
                        </div>
                        <div class="inside-box auto" style="margin: 5px 0;" v-for="(item, index) in itemForm.expressionContent.c" :key="index">
                          <div class="box-left auto boxstyle groupbox">
                            <div class="out-box auto">
                              <div class="relationbox auto" v-show="itemForm.expressionContent.c[index].c.length > 1">
                                条件关系：
                                <a-radio-group button-style="solid" v-model="itemForm.expressionContent.c[index].l" size="small">
                                  <a-radio-button :value="item.id" v-for="(item, index) in relationOptions" :key="index">
                                    {{item.name}}
                                  </a-radio-button>
                                </a-radio-group>
                              </div>
                              <div class="inside-box auto" style="margin: 5px 0;" v-for="(midItem, midIndex) in itemForm.expressionContent.c[index].c" :key="midIndex">
                                <div class="box-left auto">
                                  <div class="mid-box auto boxstyle">
                                    <div class="mid-left auto">
                                      <div class="relationbox auto">
                                        条件关系：
                                        <a-radio-group button-style="solid" v-model="itemForm.expressionContent.c[index].c[midIndex].l" size="small">
                                          <a-radio-button :value="item.id" v-for="(item, index) in relationOptions" :key="index">
                                            {{item.name}}
                                          </a-radio-button>
                                        </a-radio-group>
                                      </div>
                                      <div class="inside-box auto" v-for="(insideItem, insideIndex) in itemForm.expressionContent.c[index].c[midIndex].c" :key="insideIndex">
                                        <div class="box-left fl">
                                          <div class="inside-item auto boxstyle">
                                            <div class="item-left auto fl">
                                              <!-- <span>属性：</span> -->
                                              <a-select v-model="itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f']" size="small" style="width: calc(100% - 5px);" @change="changeType($event, index, midIndex, insideIndex)">
                                                <a-select-option :value="item.code" v-for="(item, itemIndex) in options['fields']" :key="itemIndex">
                                                  {{item.name}}
                                                </a-select-option>
                                              </a-select>
                                            </div>
                                            <div class="item-left item-mid auto fl">
                                              <!-- <span>运算符：</span> -->
                                              <a-select placeholder="请选择" v-model="itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['o']" size="small" style="width: calc(100% - 5px);">
                                                <a-select-option :value="item.symbol" v-for="(item, itemIndex) in options['operations']" :key="itemIndex" :disabled="options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['operation'].indexOf(item.code) < 0">
                                                  {{item.name}}
                                                </a-select-option>
                                              </a-select>
                                            </div>
                                            <div class="item-left item-right auto fl">
                                              <!-- <span>值：</span> -->
                                              <a-input placeholder="请输入数值" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" type="number" size="small" v-if="options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['category'] === 'number'" style="width:100%;"></a-input>
                                              <a-input placeholder="请输入内容" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" size="small" v-if="options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['category'] === 'string'" style="width:100%;"></a-input>
                                              <!-- <a-range-picker valueFormat="YYYY-MM-DD" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" size="small" v-if="options['fields'].find(item => {return  item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['category'] === 'date'" style="width:100%;"><a-icon slot="suffixIcon" type="calendar" /></a-range-picker> -->
                                              <a-date-picker valueFormat="YYYY-MM-DD" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" size="small" v-if="options['fields'].find(item => {return  item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['category'] === 'date'" style="width:100%;"><a-icon slot="suffixIcon" type="calendar" /></a-date-picker>
                                              <a-select size="small" placeholder="请选择" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" v-if="options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['category'] === 'dictionary' && options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dictionary'] !== 'usertype' && options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dictionary'] !== 'department'" style="width:100%;">
                                                <a-select-option :value="filterItem.id" v-for="(filterItem, filterIndex) in filters[options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dictionary']]" :key="filterIndex">
                                                  {{filterItem.name}}
                                                </a-select-option>
                                              </a-select>
                                              <el-cascader placeholder="请选择组织机构" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" v-if="(showDetail || isAdd) && filters['department'] && options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['category'] === 'dictionary' && options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dictionary'] === 'department'" :show-all-levels="false" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: false, emitPath: false }" :options="filters['department']" size="mini" class="custom-el-cascader" clearable style="width:100%;"></el-cascader>
                                              <el-cascader placeholder="请选择人员类别" v-model="itemForm['expressionContent']['c'][index]['c'][midIndex]['c'][insideIndex]['v']" v-if="(showDetail || isAdd) && filters['usertype'] && options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['category'] === 'dictionary' && options['fields'].find(item => { return item.code === itemForm.expressionContent.c[index].c[midIndex].c[insideIndex]['f'] })['dictionary'] === 'usertype'" :show-all-levels="false" :props="{ checkStrictly: true, label: 'name', value: 'id', children: 'children', multiple: false, emitPath: false }" :options="filters['usertype']" size="mini" class="custom-el-cascader" clearable style="width:100%;"></el-cascader>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="box-right fr">
                                          <a-icon v-show="itemForm.expressionContent.c[index].c[midIndex].c.length > 1" type="delete" class="delete-icon" @click="deleteInside(index, midIndex, insideIndex)" />
                                          <a-icon v-show="itemForm.expressionContent.c[index].c[midIndex].c.length <= 1" class="delete-icon-default" type="delete" />
                                        </div>
                                      </div>
                                      <a-button type="dashed" size="small" @click="addInside(index, midIndex)" style="width: calc(100% - 30px);">添加条件</a-button>
                                    </div>
                                  </div>
                                </div>
                                <div class="box-right auto">
                                  <a-icon type="delete" v-show="itemForm.expressionContent.c[index].c.length > 1" class="delete-icon" @click="deleteMid(index, midIndex)" />
                                  <a-icon type="delete" v-show="itemForm.expressionContent.c[index].c.length <= 1" class="delete-icon-default" />
                                </div>
                              </div>
                              <a-button type="dashed" size="small" @click="addMid(index)" style="width: calc(100% - 30px);">添加条件</a-button>
                            </div>
                          </div>
                          <div class="box-right auto">
                            <a-icon type="delete" v-show="itemForm.expressionContent.c.length > 1" class="delete-icon" @click="deleteOut(index)" />
                            <a-icon type="delete" v-show="itemForm.expressionContent.c.length <= 1" class="delete-icon-default" />
                          </div>
                        </div>
                        <a-button type="dashed" size="small" @click="addOut" style="width: calc(100% - 30px);">添加条件</a-button>
                      </div>
                    </div>
                  </a-form-model-item>
                  <a-form-model-item label="" :wrapper-col="{span: 24}" style="margin-top:10px;text-align:center;">
                    <a-button type="primary" size="small" @click="submitFilter">保存</a-button>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </div>
          </div>
        </div>
      </a-spin>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { Group } from '@/model'
export default {
  data () {
    return {
      id: '',
      boxHeight: 0,
      loading: true,
      listData: [],
      searchValue: '',
      filters: {},
      departmentName: {},
      userTypeName: {},
      itemForm: {
        name: '',
        order: 0,
        expressionName: '',
        expressionContent: {
          '@type': 'Complex',
          'l': 'and',
          'c': [
            {
              '@type': 'Complex',
              'l': 'and',
              'c': [
                {
                  '@type': 'Complex',
                  'l': 'and',
                  'c': [
                    { '@type': 'Basic', 'f': 'idCardType', 'o': undefined, 'v': undefined }
                  ]
                }
              ]
            }
          ]
        }
      },
      showDetail: false,
      relationOptions: [
        {
          id: 'or',
          name: '或者'
        },
        {
          id: 'and',
          name: '并且'
        }
      ],
      options: {
        "category": {
          "string": {
            "type": "string",
            "description": "字符串"
          },
          "date": {
            "type": "date",
            "format": "yyyy-MM-dd",
            "description": "日期"
          },
          "number": {
            "type": "number",
            "description": "数值"
          },
          "dictionary": {
            "usertype": {
              "type": "cascader",
              "description": "人员类别"
            },
            "department": {
              "type": "cascader",
              "description": "组织机构"
            },
            "atSchoolStatus": {
              "type": "select",
              "description": "在校状态"
            },
            "gender": {
              "type": "select",
              "description": "性别"
            },
            "id": {
              "type": "select",
              "description": "证件类型"
            },
            "political": {
              "type": "select",
              "description": "政治面貌"
            }
          }
        },
        "operations": [
          {
            "code": "equals",
            "symbol": "=",
            "name": "等于",
            "description": ""
          },
          {
            "code": "notequals",
            "symbol": "!=",
            "name": "不等于",
            "description": ""
          },
          {
            "code": "contains",
            "symbol": "in",
            "name": "包含",
            "description": ""
          },
          {
            "code": "greater",
            "symbol": ">",
            "name": "大于",
            "description": ""
          },
          {
            "code": "smaller",
            "symbol": "<",
            "name": "小于",
            "description": ""
          }
        ],
        "fields": [
          {
            "code": "idCardType",
            "name": "证件类型",
            "category": "dictionary",
            "dictionary": "id",
            "operation": [
              "equals",
              "notequals"
            ]
          },
          {
            "code": "department",
            "name": "所在单位",
            "category": "dictionary",
            "dictionary": "department",
            "operation": [
              "equals",
              "contains"
            ]
          },
          {
            "code": "type",
            "name": "人员类别",
            "category": "dictionary",
            "dictionary": "usertype",
            "operation": [
              "equals",
              "contains"
            ]
          },
          {
            "code": "sex",
            "name": "性别",
            "category": "dictionary",
            "dictionary": "gender",
            "operation": [
              "equals",
              "notequals"
            ]
          },
          {
            "code": "political",
            "name": "政治面貌",
            "category": "dictionary",
            "dictionary": "political",
            "operation": [
              "equals",
              "notequals"
            ]
          },
          {
            "code": "startDate",
            "name": "入校日期",
            "category": "date",
            "operation": [
              "equals",
              "notequals",
              "greater",
              "smaller"
            ]
          },
          {
            "code": "endDate",
            "name": "预计离校日期",
            "category": "date",
            "operation": [
              "equals",
              "notequals",
              "greater",
              "smaller"
            ]
          },
          {
            "code": "atSchoolStatus",
            "name": "在校状态",
            "category": "dictionary",
            "dictionary": "atSchoolStatus",
            "operation": [
              "equals",
              "notequals"
            ]
          }
        ]
      },
      current_row: {},
      isAdd: false,
      submitStatus: false
    }
  },
  watch: {
    'itemForm.expressionContent': {
      handler: function (val) {
        let submitStatus = true
        let Names = ''
        for (let key in this.itemForm.expressionContent['c']) {
          let outItem = this.itemForm.expressionContent['c'][key]
          let outItemName = this.itemForm.expressionContent['c'].length > 1 ? '{' : ''
          for (let midKey in outItem['c']) {
            let midItem = outItem['c'][midKey]
            let midItemName = outItem['c'].length > 1 ? '[' : ''
            for (let insideKey in midItem['c']) {
              let insideItem = midItem['c'][insideKey]
              if (insideItem.f && insideItem.o && insideItem.v) {
                let item = midItem['c'].length > 1 ? '(' : ''
                item += `${this.getFname(insideItem.f)} ${this.getOname(insideItem.o)} ${this.getVname(insideItem.f, insideItem.v)}`
                item += midItem['c'].length > 1 ? ')' : ''
                if (insideKey < midItem['c'].length - 1) {
                  item += ` ${this.getLname(midItem['l'])} `
                }
                // console.log('item', item)
                midItemName += item
              } else {
                submitStatus = false
              }
            }
            midItemName += outItem['c'].length > 1 ? ']' : ''
            if (midKey < outItem['c'].length - 1) {
              midItemName += ` ${this.getLname(outItem['l'])} `
            }
            // console.log('midItemName', midItemName)
            outItemName += midItemName
          }
          outItemName += this.itemForm.expressionContent['c'].length > 1 ? '}' : ''
          if (key < this.itemForm.expressionContent['c'].length - 1) {
            outItemName += ` ${this.getLname(this.itemForm.expressionContent['l'])} `
          }
          // console.log('outItemName', outItemName)
          Names += outItemName
        }
        // console.log('Names', Names)
        this.itemForm.expressionName = Names
        this.submitStatus = submitStatus
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  mounted () {
    this.boxHeight = document.body.clientHeight - 250
  },
  methods: {
    async deleteFilter (row) {
      this.loading = true
      let res = await Group.deleteFilter(row.id)
      this.loading = false
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getList()
        if (this.current_row.id === row.id) {
          this.init()
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getLname (data) {
      let value = this.relationOptions.find(item => {
        return item.id === data
      })
      return value.name
    },
    getFname (data) {
      let value = this.options.fields.find(item => {
        return item.code === data
      })
      return value.name
    },
    getOname (data) {
      let value = this.options.operations.find(item => {
        return item.symbol === data
      })
      return value.name
    },
    getVname (type, data) {
      let typeItem = this.options.fields.find(item => {
        return item.code === type
      })
      if (typeItem['category'] === 'dictionary') {
        if (typeItem['dictionary'] === 'department') {
          return this.departmentName[data]
        } else if (typeItem['dictionary'] === 'usertype') {
          return this.userTypeName[data]
        } else {
          var options = this.filters[typeItem['dictionary']]
          let value = options.find(item => {
            return item.id === data
          })
          return value.name
        }
      } else {
        return data
      }
    },
    async submitFilter () {
      this.itemForm = JSON.parse(JSON.stringify(this.itemForm))
      if (!this.itemForm.name) {
        this.$message.error('请填写名称!')
        return false
      }
      if (!this.submitStatus) {
        this.$message.error('请填写完整!')
        return false
      }
      let res = null
      this.loading = true
      if (this.isAdd) {
        res = await Group.addFilter(this.itemForm)
      } else {
        res = await Group.changeFilter(this.current_row.id, this.itemForm)
      }
      this.loading = false
      if (!res['code']) {
        this.$message.success('保存成功！')
        this.getList()
        if (this.isAdd) {
          this.init()
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    init () {
      this.isAdd = false
      this.current_row = {}
      this.itemForm = {
        name: '',
        order: 0,
        expressionName: '',
        expressionContent: {
          '@type': 'Complex',
          'l': 'and',
          'c': [
            {
              '@type': 'Complex',
              'l': 'and',
              'c': [
                {
                  '@type': 'Complex',
                  'l': 'and',
                  'c': [
                    { '@type': 'Basic', 'f': 'idCardType', 'o': undefined, 'v': undefined }
                  ]
                }
              ]
            }
          ]
        }
      }
    },
    addFilter () {
      this.isAdd = true
      this.itemForm = {
        name: '',
        order: 0,
        expressionName: '',
        expressionContent: {
          '@type': 'Complex',
          'l': 'and',
          'c': [
            {
              '@type': 'Complex',
              'l': 'and',
              'c': [
                {
                  '@type': 'Complex',
                  'l': 'and',
                  'c': [
                    { '@type': 'Basic', 'f': 'idCardType', 'o': undefined, 'v': undefined }
                  ]
                }
              ]
            }
          ]
        }
      }
    },
    chooseList (row) {
      this.isAdd = false
      this.current_row = row
      this.getFilterDetail()
    },
    changeType(event, outIndex, midIndex, insideIndex) {
      let option = this.options['fields'].find(item => {
        return item.code === event
      })
      // console.log(option)
      if (option.category === 'dictionary') {
        this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['v'] = undefined
      } else if (option.category === 'number') {
        this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['v'] = 0
      } else if (option.category === 'date') {
        this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['v'] = []
      } else {
        this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['v'] = ''
      }
      this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'][insideIndex]['o'] = undefined
      this.itemForm = JSON.parse(JSON.stringify(this.itemForm))
    },
    addOut () {
      this.itemForm.expressionContent['c'].push({
        '@type': 'Complex',
        'l': 'and',
        'c': [
          {
            '@type': 'Complex',
            'l': 'and',
            'c': [
              { '@type': 'Basic', 'f': 'idCardType', 'o': undefined, 'v': undefined }
            ]
          }
        ]
      })
    },
    deleteOut (outIndex) {
      this.itemForm.expressionContent['c'].splice(outIndex, 1)
    },
    addMid (outIndex) {
      this.itemForm.expressionContent['c'][outIndex]['c'].push({
        '@type': 'Complex',
        'l': 'and',
        'c': [
          { '@type': 'Basic', 'f': 'idCardType', 'o': undefined, 'v': undefined }
        ]
      })
    },
    deleteMid (outIndex, midIndex) {
      this.itemForm.expressionContent['c'][outIndex]['c'].splice(midIndex, 1)
    },
    addInside (outIndex, midIndex) {
      this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'].push({
        '@type': 'Basic', 'f': 'idCardType', 'o': undefined, 'v': undefined
      })
      this.itemForm = JSON.parse(JSON.stringify(this.itemForm))
    },
    deleteInside (outIndex, midIndex, insideIndex) {
      this.itemForm.expressionContent['c'][outIndex]['c'][midIndex]['c'].splice(insideIndex, 1)
    },
    async getList () {
      this.loading = true
      let res = await Group.getFilterList()
      this.loading = false
      if (!res['code']) {
        this.listData = res['data']
        if (this.id) {
          let item = this.listData.find(item => {return Number(item.id) === Number(this.id)})
          if (item) {
            this.current_row = item
            this.isAdd = false
            this.getFilterDetail()
          }
          this.id = ''
        }
      }
    },
    async getFilterDetail () {
      this.showDetail = false
      this.loading = true
      let res = await Group.getFilterDetail(this.current_row.id)
      this.loading = false
      if (!res['code']) {
        this.itemForm = {
          name: res['data']['name'],
          order: res['data']['order'],
          expressionName: res['data']['expressionName'],
          expressionContent: res['data']['expressionContent']
        }
        setTimeout(() => {
          this.showDetail = true
        }, 0);
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    trunDepartmentData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunDepartmentData(data[k]['children'])
        }
      }
    },
    trunUserTypeData (data) {
      for (let k in data) {
        this.userTypeName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunUserTypeData(data[k]['children'])
        }
      }
    },
    async getCode () {
      let res = await Group.getCode('basic')
      for (let key in res['data']) {
        this.filters[key] = res['data'][key]
      }
      let res1 = await Group.getCode('department')
      this.filters['department'] = res1['data']
      this.trunDepartmentData(res1['data'])
      let res2 = await Group.getCode('usertype')
      this.filters['usertype'] = res2['data']
      this.trunUserTypeData(res2['data'])
      this.getList()
    }
  },
  created () {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id
    }
    this.getCode()
  }
}
</script>

<style scoped>
.drawer-bar {
  padding: 8px 0 20px;
}

.depart-box {
  width: 49%;
}
.depart-l {
  border: 1px solid rgba(0,0,0,0.15);
  margin-right: 20px;
  width: 250px;
  padding: 5px;
}
.depart-r {
  width: calc(100% - 30% - 250px);
  min-width: 700px;
}
.depart-title {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba(0,0,0,0.02);;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.depart-title-l {
  margin-left: 24px;
}
.depart-title-r {
}
.depart-title-r span {
  margin-left: 15px;
  color: #0091FF;
  font-size: 12px;
}

.tree-node .fr{
  color: #1890FF;
  margin-right: 10px;
  display: none;
}
.depart-con .delete-icon {
  color: #1890FF;
}
.depart-con .delete-icon-default {
  color: rgba(0,0,0,0.25);
}
.ant-tree-node-content-wrapper:hover .fr {
  display: block;
}
.tree-node .fr i {
  margin-right: 10px;
}

.ant-form-item {
  width: 100%;
}
.right-form {
  margin-top: 5px;
}

.inside-box {
  display: flex;
}
.box-left {
  flex: 1 1 300px;
}
.box-right {
  flex: 0 0 30px;
  text-align: center;
  align-items: center;
  display: flex;
}
.box-right i {
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  margin-right: 5px;
}
.item-left {
  width: 33.33%;
}
.boxstyle {
  border: 1px solid rgba(0,0,0,0.15);
  padding: 5px 10px;
  border-radius: 4px;
}
.inside-item {
  width: 100%;
  margin: 5px 0;
  border:none;
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding: 5px 0;
}

.left-con {
  margin-top: 10px;
  overflow-y: auto;
}
.left-con ul li {
  line-height: 28px;
  padding: 0 5px;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
}
.left-con ul li.cur {
  background: #E6F7FF;
}
.left-con ul li i {
  display: none
}
.left-con ul li:hover {
  background: #E6F7FF;
}
.left-con ul li:hover i {
  display: block;
  margin-top: 8px;
  color: #1890FF;
}
.left-con ul li span.fl {
  display: block;
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: auto;
  overflow: hidden;
}

.mid-box {
  background: #EBEBEB;
}
.groupbox {
  background: #F5F5F5;
}
</style>
